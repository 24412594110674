export const fields = [
    { key: 'image', label: "Cover", _style:'min-width:100px' },
    { key: 'tahun', label: "Tahun", _style:'min-width:100px' },
    { key: 'title', label: "Nama Dokumen", _style:'min-width:100px' },
    { key: "document_type", label: "Tipe Dokumen", _style:'min-width:50px'},
    { key: 'upload_by', label: "Pengunggah", _style:'min-width:100px;' },
    { key: 'updated_at', label: "Terakhir Diperbarui", _style:'min-width:100px;' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
export const fieldsType = [

    { key: 'name', label: "Nama Tipe Dokumen", _style:'min-width:100px' },
    { key: 'created_at', label: "Tanggal Dibuat", _style:'min-width:100px;' },
    { key: 'updated_at', label: "Terakhir Diperbarui", _style:'min-width:100px;' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
export const fieldsPublic = [
    { key: 'image', label: "Cover", _style:'min-width:100px' },
    { key: 'tahun', label: "Tahun", _style:'min-width:100px' },
    { key: 'title', label: "Nama Dokumen", _style:'min-width:100px' },
    { key: 'document_type', label: "Tipe Dokumen", _style:'min-width:50px' },
    { key: 'created_at', label: "Tanggal Dibuat", _style:'min-width:100px;' },
    { key: 'updated_at', label: "Terakhir Diperbarui", _style:'min-width:100px;' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
export const LaporanMasyarakat = [

    { key: 'name', label: "Nama", _style:'min-width:75px' },
    { key: 'address', label: "Alamat", _style:'min-width:100px;' },
    { key: 'phone_number', label: "No. Telepon", _style:'min-width:75px;' },
    { key: "report", label: 'Isi Laporan', _style: 'min-width:100px'},
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
export const LaporanMasyarakat_opd = [

    { key: 'name', label: "Nama", _style:'min-width:100px' },
    { key: 'address', label: "Alamat", _style:'min-width:100px;' },
    { key: 'phone_number', label: "No. Telepon", _style:'min-width:100px;' },
    { key: "report", label: 'Isi Laporan', _style: 'min-width:125px'},
]